// Dependencies
import React from "react";
import { graphql, Link } from "gatsby";
import PropTypes from "prop-types";
import { useLocation } from "@reach/router";
import { FacebookShareButton } from "react-share";
// Hooks
import useShare from "../hooks/useShare";
import useSettings from "../hooks/useSettings";
// Components
import AudioPlayer from "../components/AudioPlayer";
import Card from "../components/Card";
import Image from "../components/Image";
import Header from "../components/Header";
import Layout from "../components/Layout";
import FolderWithCards from "../components/FolderWithCards";

const TwitterShareLink = ({ text, url }) => {

  const truncateQuote = text.length > 180 ? `${text.substring(0, 180)}…` : text;
  const tweetParams = encodeURI(`text=Some helpful advice I found through Paul Smith's Foundation:\n\n"${truncateQuote}"\n\n&url=${url}`);

  return (
    <a href={`https://twitter.com/intent/tweet?${tweetParams}`} className="dark-link" target="_blank" rel="noopener noreferrer">Twitter</a>
  )
}

const EmailLink = ({ letPaulKnowEmail, letPaulKnowLabel, quote = "", link }) => {

  const mailto = encodeURI(`mailto:${letPaulKnowEmail}?subject=Thanks for the help Paul&body=${quote}\n\n${link}\n\nThis advice was very useful, as it helped me…`);

  return (
    <a href={mailto} className="dark-link" target="_blank" rel="noopener noreferrer">{letPaulKnowLabel}</a>
  )
}

const AdvicePage = ({ data }) => {

  // Content
  const { letPaulKnow, letPaulKnowEmail, letPaulKnowLabel } = useSettings();
  const location = useLocation();
  const { quote, slug, images, category, source, tags, audioClip } = data.contentfulAdvice;
  const adviceFromCategory = data.contentfulCategory.advice;
  const shareUrl = `https://www.paulsmithsfoundation.com${location.pathname}`;
  const shareImage = images && images[0];

  // UI
  const { share, hasShared } = useShare({ url: shareUrl });

  return (
    <Layout title={`Paul on ${category[0].title}`} description={`"${quote.text}"`} shareImage={shareImage}>
      <Header sticky />

      {/* Advice page content */}
      <div className={`advice-view ${images ? 'has-images' : ''}`}>
        <Card {...data.contentfulAdvice} debug />
        {images && (
          <div className="advice-images mauto">
            <Image {...data.contentfulAdvice.images[0]} />
          </div>
        )}
      </div>

      {/* Other bits of related context */}
      <div className={`advice-info mb ${images ? 'has-images' : ''}`}>

        {audioClip && audioClip.mp3.file && (
          <AudioPlayer {...audioClip} />
        )}

        {source && (
          <div className="container p3 show-on-mobile">
            <div className="advice-info-section has-border">
              <div>SOURCE: </div>
              <div>
                <span>{`${source.outlet} `}</span>
                {source.link && (
                  <a href={source.link} className="dark-link" target="_blank" rel="noopener noreferrer">View source</a>
                )}
              </div>
            </div>
          </div>
        )}

        {tags && (
          <div className="container p3 show-on-mobile">
            <div className="advice-info-section has-border">
              <div>TAGS: </div>
              <div className="advice-info-tags">
                {tags.map((entry, index) => (
                  <span key={`${slug}-tag-${index}`}>
                    <Link to={`/advice?${entry.name}`} className="upcase dark-link" >{`#${entry.name}`}</Link>
                  </span>
                ))}
              </div>
            </div>
          </div>
        )}

        <div className="container p3">
          <div className="advice-info-section has-border">
            <div>SHARE:</div>
            <div className="advice-info-tags">
              <span>
                <FacebookShareButton className="dark-link share-link" resetButtonStyle={false} url={shareUrl} quote={`Some helpful advice I found through Paul Smith's Foundation: \n\n "${quote.text}"`}>Facebook</FacebookShareButton>
              </span>
              <span>
                <TwitterShareLink text={quote.text} url={shareUrl} />
              </span>
              <span>
                <button className="dark-link" onClick={share}>
                  {hasShared || "Copy Link"}
                </button>
              </span>
            </div>
          </div>
        </div>

        <div className="container p3">
          <div className="advice-info-section has-border">
            <div className="upcase">{letPaulKnow}</div>
            <div>
              <EmailLink quote={`"${quote.text}"`} link={shareUrl} letPaulKnowLabel={letPaulKnowLabel} letPaulKnowEmail={letPaulKnowEmail} />
            </div>
          </div>
        </div>

        {/* <div className="container">
          <div className="advice-info-section">
            <div>
              <h1>More from this category ↓</h1>
            </div>
          </div>
        </div> */}
      </div>

      {/* Category (related advice) */}
      <FolderWithCards category={category[0]} advice={adviceFromCategory} />
    </Layout>

  )
}

AdvicePage.propTypes = {
  data: PropTypes.object.isRequired
}

export default AdvicePage

export const pageQuery = graphql`
  query AdvicePageQuery($slug: String!, $category: String!) {
    contentfulAdvice(slug: {eq: $slug}) {
      ...AdviceFragment
    }
    contentfulCategory(slug: {eq: $category}) {
      advice {
        ...AdviceFragment
      }
    }
    # relatedCategory: allContentfulCategory(slug: {eq: $category}) {
    #   advice {
    #     ...AdviceFragment
    #   }
    # }
    # adviceFromCategory: allContentfulAdvice(filter: {category: {slug: {eq: $category}}}) {
    #   nodes {
    #     ...AdviceFragment
    #   }
    # }
  }
`
