// Dependencies
import React, { useState, useEffect } from "react";
import { useAudio } from "react-use";
// import { graphql } from "gatsby";
// import PropTypes from "prop-types";
// Hooks
// import useMouse from "../hooks/useMouse";
// Components
// import { AudioIcon } from "../components/Icons";

const Icon = ({ className }) => (
  <svg className={className} width="44" height="35" viewBox="0 0 44 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g fillRule="evenodd" clipRule="evenodd" fill="black">
      <path className="speaker" d="M23.988 0.69491C24.2215 0.828481 24.3656 1.07691 24.3656 1.34595V33.654C24.3656 33.923 24.2215 34.1714 23.988 34.305C23.7544 34.4386 23.4672 34.4368 23.2354 34.3004L9.68048 26.327H1C0.585786 26.327 0.25 25.9912 0.25 25.577V9.42295C0.25 9.00874 0.585786 8.67295 1 8.67295H9.68048L23.2354 0.699497C23.4672 0.563089 23.7544 0.561339 23.988 0.69491ZM22.8656 2.65726L10.265 10.0694C10.1497 10.1372 10.0184 10.173 9.88471 10.173H1.75V24.827H9.88471C10.0184 24.827 10.1497 24.8627 10.265 24.9305L22.8656 32.3427V2.65726Z" />
      <g className="wave">
        <path d="M31.3946 10.7826C31.6577 10.4627 32.1303 10.4167 32.4502 10.6798C34.4169 12.2975 35.6732 14.7524 35.6732 17.5C35.6732 20.072 34.5723 22.3878 32.8182 24.0002C32.5133 24.2805 32.0388 24.2605 31.7585 23.9555C31.4782 23.6506 31.4982 23.1761 31.8031 22.8958C33.2611 21.5556 34.1732 19.6349 34.1732 17.5C34.1732 15.2198 33.1325 13.1833 31.4973 11.8383C31.1774 11.5751 31.1314 11.1025 31.3946 10.7826Z" />
      </g>
      <g className="wave">
        <path d="M36.9688 4.88811C37.2429 4.57756 37.7169 4.54801 38.0274 4.8221C41.5356 7.91843 43.75 12.451 43.75 17.4999C43.75 22.5488 41.5356 27.0814 38.0274 30.1777C37.7169 30.4518 37.2429 30.4223 36.9688 30.1117C36.6947 29.8012 36.7243 29.3272 37.0348 29.0531C40.234 26.2295 42.25 22.1006 42.25 17.4999C42.25 12.8993 40.234 8.77038 37.0348 5.94671C36.7243 5.67261 36.6947 5.19866 36.9688 4.88811Z" />
      </g>
    </g>
  </svg>
)

const PlayBar = ({ css }) => (
  <div className="audio-play-bar">
    <div className="audio-play-head" style={css} />
  </div>
)

function formatDuration(s) {
  let m = Math.floor(s / 60);
  m = (m >= 10) ? m : "0" + m;
  s = Math.floor(s % 60);
  s = (s >= 10) ? s : "0" + s;
  return m + ":" + s;
}

const Player = ({ title, mp3 }) => {

  // UX & UI
  const [audio, state, controls, ref] = useAudio({
    src: mp3.file.url,
    autoPlay: false,
  });

  if (state) {
    if (state.time === state.duration) {
      controls.seek(0)
      controls.pause()
    }
  }

  const duration = state ? formatDuration(state.duration - state.time) : "0:00";

  // CSS
  const playHeadCss = {
    left: state && state.time ? `${state.time / state.duration * 100}%` : '0%'
  };

  return (
    <div className="container h1">
      {audio}
      <div className="advice-audio-section">
        <div>
          <span className="audio-title">{title} <span className="inlb">— {duration}</span></span>
        </div>
        <div className="audio-player">
          <Icon className={`audio-icon ${state && !state.paused ? 'is-playing' : ''}`} />
          <button className="upcase dark-link" onClick={state && !state.paused ? controls.pause : controls.play}>
            {state && !state.paused ? 'Pause' : 'Listen'}
          </button>
        </div>
      </div>
    </div>
  )
}

const AudioPlayer = (props) => {

  const [ready, set] = useState(false)

  useEffect(() => {
    set(true)
  }, []);

  return (
    ready && (
      <Player {...props} />
    )
  )
}

export default AudioPlayer
